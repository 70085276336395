@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,500;0,700;0,900;1,400&display=swap');

@font-face {
  font-family: 'Arvo-Bold';
  src: url("./assets/Arvo-Bold.ttf");
}

html {
  box-sizing: border-box !important;
}

*,
*::before,
*::after {
  box-sizing: inherit !important;
}

body {
  margin: 0;
  font-family: "Roboto";
  // font-family: Arvo-Bold, serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;

  * {
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
}

#root {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100%
}

canvas {
  display: none;
}

h1 {
  font-size: 32px;
}

h2 {
  font-size: 28px;
}

h1,
h2 {
  margin: 5px;
  padding: 0;

  @media (max-width: 800px) {
    font-size: 18px;
  }
}

a {
  text-decoration: none;
  color: lightblue;
  font-weight: 900;

  &:hover {}

  @media (max-width: 800px) {
    font-size: 14px;
  }
}

header {
  width: 100%;
}

nav {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 12px;
  height: 72px;
  justify-content: flex-start;
  position: relative;
  color: whitesmoke;
  background-color: rgb(33, 33, 33);

  @media (max-width: 800px) {
    padding: 5px;
    height: 52px;
  }

  &>* {
    margin: 0;
    padding: 0;
  }

  h1 {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
}


.Cards {
  display: flex;
  max-width: 700px;
  flex-wrap: wrap;
  justify-content: center;
  position: relative;
  top: 18px;

  .Card {

    // -webkit-user-select:none;
    // -webkit-touch-callout:none;
    img {
      height: 400px;
      width: 293.05px;
      margin: 25px;
      display: inline-block;
      box-shadow: 3px 3px 12px -3px rgba(0, 0, 0, 0.6);
      border-radius: 20px;
      position: relative;
      // pointer-events: none;
      user-select: none;
      touch-action: pan-y;
    }

    .react-contextmenu {
      background: #fff;
      box-shadow: 10px 10px 40px -2px rgba(0, 0, 0, 0.27);
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      color: #000;
      width: auto;
      height: auto;
      padding: 0;
      z-index: 111;

      .react-contextmenu-item {
        padding: 0.8em;
        font-weight: 800;
        user-select: none;
        width: 100%;

        &:last-child {
          border-bottom: none;
          border-radius: 0 0 5px 5px;
        }

        &:first-child {
          border-radius: 5px 5px 0 0;
        }

        &:hover {
          background-color: hsl(0, 0%, 85%);
          cursor: pointer;
        }

        &.safety {
          color: hsl(123, 100%, 30%);
        }

        &.danger {
          color: hsl(0, 73%, 35%);
        }

        &.react-contextmenu-item--divider {
          background-color: rgb(128, 128, 128);
          padding: 0.75px;
        }
      }

      &.white {
        background-color: rgb(37, 69, 121);
        color: #fff;

        .react-contextmenu-item {
          &:hover {
            background-color: hsl(217, 53%, 40%);
          }

          &.safety {
            color: hsl(122, 88%, 66%);
          }

          &.danger {
            color: hsl(0, 100%, 82%);
          }
        }
      }


    }
  }
}


main {
  display: flex;
  width: 100%;
  height: calc(100vh - 72px);

  &>* {
    width: 50%;
    min-width: 390px;
  }

  .existing {
    height: 10px;
  }

  .Social,
  .add-new {
    padding: 12px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

  }

  .Social {

    h2 {
      font-weight: 500;
      color: #444;
    }

    .Card {
      img {
        // height: 333.333333333px;
        // width: 244.208333333px;
        margin: 25px;
        display: inline-block;
        box-shadow: 3px 3px 12px -3px rgba(0, 0, 0, 0.6);
        border-radius: 20px;
        position: relative;
        // pointer-events: none;
        user-select: none;
        touch-action: pan-y;
      }
    }
  }

  .add-new {
    background-color: #f0f0f0;

    // &::after {
    //   content: "";
    //   position: absolute;

    //   right: 0;
    //   top: 50%;
    //   transform: translateY(-50%);
    //   border: 0.5px solid black;
    //   height: 50%;
    //   width: 1px;
    //   z-index: 999;
    // }


    .NewCard {
      display: flex;
      width: 100%;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .Cards {
      display: flex;
      width: 100%;
      height: 100%;
      overflow: scroll;
    }
  }

  @media (max-width: 780px) {
    flex-wrap: wrap;

    &>* {
      width: 100%;
    }
  }
}


.NewCard {
  form {
    padding: 1em;
    user-select: none;
  }

  hr {
    user-select: none;
  }

  input {
    height: 70px;
    font-size: 2em;
    padding: 0.5em;
    font-family: Arvo-Bold, serif;
    margin: 0em;
    border: none;
    border-radius: 10px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    background-color: hsl(224, 100%, 97%);
    outline: none;

    &[type=text] {
      width: 100%;
      max-width: 800px;
      // min-width: 200px;
      // max-width: 10vw;
    }

    &::-webkit-input-placeholder {
      width: 1em;
    }
  }

  .input-and-submit {
    max-width: 100%;
  }

  button {
    height: 70px;
    // line-height: 70px;
    font-size: 2em;
    padding: 0.5em;
    margin: 0;
    border-radius: 10px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    position: relative;
    // left: -0.1em;
    border: none;
    // background: hsl(224, 80%, 90%);
    // font-family: Arvo-Bold, serif;
    font-weight: 700;
    cursor: pointer;

    &[disabled] {
      cursor: not-allowed;
    }

    span {
      // height: 70px;
      // line-height: 70px;
    }
  }
}

.flex-row {
  display: flex;
  align-items: center;
}

// label {
//   display: none;
// }

.color-selections {
  display: flex;
  justify-content: space-around;
  margin: 0.5em;
  margin-bottom: 18px;
  ;
  width: 100%;
  border-radius: 10px;

  .color-selection {
    width: 100%;
    height: 4em;
    user-select: none;

    &:first-child,
    &:first-child input:checked+label::after {
      border-radius: 10px 0 0 10px;
    }

    &:last-child,
    &:last-child input:checked+label::after {
      border-radius: 0 10px 10px 0;
    }

    input+label {
      transition: all 0.2s ease-in-out;
    }

    input:not(:checked)+label {
      font-weight: 400;
      opacity: 0.5;
    }

    input:checked+label {
      font-weight: 800;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: default;
      opacity: 1;
    }

    input:checked+label::after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0.3;
      background-color: hsl(217, 90%, 70%);
    }

    label {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      height: 100%;
      width: 100%;
    }

    &.white label::before {
      content: "";
      width: 12px;
      height: 12px;
      margin-right: 5px;
      z-index: 10;
      border: 2px solid black;
      background-color: white;
    }

    &.black label::before {
      content: "";
      width: 12px;
      height: 12px;
      margin-right: 5px;
      z-index: 10;
      border: 2px solid rgb(192, 192, 192);
      background-color: hsl(217, 53%, 40%);
      ;
    }

  }

  input {
    display: none;
  }

  label {
    cursor: pointer;
  }
}

@media (max-width: 600px) {
  .NewCard {
    input {
      &[type=text] {
        width: calc(100vw - 20%);
        // min-width: 230px;
        height: 60px;
      }
    }

    .MuiTextField-root {
      width: 50vw;
    }

    input,
    button {
      height: 60px;
      font-size: 1.5em;
    }
  }
}